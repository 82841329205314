import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import api from "../api";
import { AuthContext } from "../context/GlobalContextProvider";
import { Button } from "antd";
import { navigate } from "gatsby";

function Orcamento() {
  const [page, setPage] = useState();
  const context = useContext(AuthContext);
  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    const { data } = await api.get("/contato");
    setPage(data);
  };

  return (
    <Layout titlePage='Contato' loading={!page}>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Contato"
      />
      {context?.user && (
        <Button
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/contato`)}
        >
          Editar
        </Button>
      )}
      <div className="lg:flex py-24 md:mx-10">
        <div className="mx-2 md:mx-4 w-full lg:w-3/5">
          <h2 className="mb-6">Como chegar</h2>
          <iframe
            width="100%"
            title="SoftinLocationMap"
            height="360"
            frameBorder="0"
            style={{ border: 0, display: "block" }}
            src={`https://maps.google.com/maps?q=${encodeURI(
              page?.endereco
            )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
            allowFullScreen=""
          />
        </div>
        <div className="mx-2 md:mx-8 mt-5 lg:mt-0 lg:w-1/2 xl:w-2/4">
          <h2 className="mb-6">Base operacional</h2>
          <div className="flex mb-2">
            <p className="flex text-base items-center">{page?.endereco}</p>
          </div>
          <div className="flex mb-2">
            <p className="flex text-base items-center">{page?.telefone}</p>
          </div>
          <div className="flex mb-2">
            <p className="flex text-base items-center">{page?.email}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Orcamento;
